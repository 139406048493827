import React from 'react';
import { ArrowRight, Mail, ChevronRight } from 'lucide-react';

const CTASection = () => {
  return (
    <section className="relative py-24 overflow-hidden">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-r from-blue-900 via-blue-800 to-indigo-900" />
      
      {/* Animated background circles */}
      <div className="absolute inset-0">
        <div className="absolute top-0 -left-4 w-72 h-72 bg-blue-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob" />
        <div className="absolute top-0 -right-4 w-72 h-72 bg-indigo-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-2000" />
        <div className="absolute -bottom-8 left-20 w-72 h-72 bg-purple-500 rounded-full mix-blend-multiply filter blur-xl opacity-20 animate-blob animation-delay-4000" />
      </div>

      <div className="relative container mx-auto px-4">
        <div className="max-w-5xl mx-auto">
          {/* Two Column Layout */}
          <div className="grid md:grid-cols-2 gap-12 items-center">
            {/* Left Column - Main Content */}
            <div className="text-left">
              <h2 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6 leading-tight">
                Transform Your VLSI Design Journey
              </h2>
              <p className="text-lg text-white/90 mb-8 leading-relaxed">
                Join industry leaders who trust iVLSI Technologies for cutting-edge semiconductor solutions and expert consultation.
              </p>
              
              {/* Action Buttons with improved styling */}
              <div className="flex flex-col sm:flex-row gap-4">
                <a 
                  href="/contact" 
                  className="
                    group inline-flex items-center justify-center
                    px-8 py-4 bg-white text-blue-900 
                    rounded-lg font-semibold text-lg
                    transform transition-all duration-200
                    hover:bg-blue-50 hover:scale-105
                    shadow-lg hover:shadow-xl
                  "
                >
                  Schedule Consultation
                  <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </a>
                <a 
                  href="/services" 
                  className="
                    group inline-flex items-center justify-center
                    px-8 py-4 border-2 border-white/80 
                    text-white rounded-lg font-semibold text-lg
                    transform transition-all duration-200
                    hover:bg-white/10 hover:scale-105
                  "
                >
                  Explore Solutions
                  <ChevronRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>

            {/* Right Column - Contact Card */}
            <div className="relative">
              <div className="absolute inset-0 bg-gradient-to-r from-blue-400/20 to-indigo-400/20 rounded-2xl blur-2xl" />
              <div className="relative p-8 rounded-2xl bg-white/10 backdrop-blur-sm border border-white/20">
                <div className="flex items-center gap-4 mb-6">
                  <div className="p-3 bg-white/10 rounded-lg">
                    <Mail className="w-8 h-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-2xl font-bold text-white">Get in Touch</h3>
                    <p className="text-white/80">24-hour response time</p>
                  </div>
                </div>
                
                <p className="text-white/90 mb-8 text-lg">
                  Our VLSI design experts are ready to discuss your project requirements and provide tailored solutions.
                </p>

                <a 
                  href="mailto:info@ivlsi.com"
                  className="
                    group flex items-center justify-between
                    w-full px-6 py-4 bg-white/10
                    rounded-lg border border-white/20
                    text-white font-semibold
                    hover:bg-white/20 transition-all duration-200
                  "
                >
                  <span>Send us an Email</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;