"use client"
import React, { useState } from 'react';
import { ChevronDown, Menu, X, ArrowRight } from 'lucide-react';
import Image from 'next/image';
import Link from 'next/link';

const HeaderMenu = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeMobileMenus, setActiveMobileMenus] = useState<string[]>([]);

  const toggleMobileMenu = (menu: string) => {
    setActiveMobileMenus(
      activeMobileMenus.includes(menu)
        ? activeMobileMenus.filter(item => item !== menu)
        : [...activeMobileMenus, menu]
    );
  };

  // New function to handle link clicks
  const handleLinkClick = () => {
    setIsMobileMenuOpen(false);
  };

  const menuItems = [
    {
      title: 'Home',
      href: '/'
    },
    {
      title: 'About',
      href: '/about'
    },
    {
      title: 'Services',
      submenu: [
        { title: 'VLSI Design Services', href: '/services/vlsi-design' },
        { title: 'Semiconductor IP Development', href: '/services/semiconductor-ip' },
        { title: 'Analog & Mixed-Signal Design', href: '/services/ams-design' },
        { title: 'FPGA Design & Prototyping', href: '/services/fpga-design' },
        { title: 'Embedded Systems Development', href: '/services/embedded-systems' },
        { title: 'PCB Design & Layout', href: '/services/pcb-design' },
        { title: 'Manufacturing Support', href: '/services/manufacturing-support' },
        { title: 'Turnkey Solutions', href: '/services/turnkey-solutions' },
        { title: 'Manpower Solutions', href: '/services/manpower' }
      ]
    },
    {
      title: 'Industries',
      href: '/industries'
    },
    {
      title: 'Blog',
      href: '/blog'
    },
    {
      title: 'Career',
      href: '/careers'
    },
    {
      title: 'Contact',
      href: '/contact'
    }
  ];

  const DesktopSubMenu = ({ items }: { items: { title: string; href: string }[] }) => (
    <div className="absolute top-full left-0 pointer-events-none group-hover:pointer-events-auto">
      <ul className="bg-white min-w-[300px] rounded-lg shadow-xl border border-gray-200 p-1 
        transform opacity-0 scale-95 
        group-hover:opacity-100 group-hover:scale-100 
        transition-all duration-200 ease-in-out 
        backdrop-blur-lg bg-white/95 
        mt-2"
      >
        {items.map((item, index) => (
          <li key={index}>
            <Link 
              href={item.href}
              onClick={handleLinkClick}
              className="block px-4 py-2.5 text-gray-700 rounded-md text-sm font-medium 
                hover:bg-gray-50 hover:text-blue-600 transition-all duration-200"
            >
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );

  return (
    <header className="bg-white shadow-md relative z-50">
      <div className="bg-gradient-to-r from-blue-600 to-indigo-600 h-0.5" />
      <nav className="container mx-auto px-4">
        <div className="flex justify-between items-center h-24">
          {/* Logo */}
          <Link href="/" onClick={handleLinkClick} className="flex items-center space-x-2">
            <Image
              src="/ivlsi-logo.png"
              width={130}
              height={50}
              alt="iVLSI Technologies"
              priority
            />
          </Link>

          {/* Desktop menu */}
          <div className="hidden md:flex items-center">
            <div className="flex items-center space-x-1">
              {menuItems.map((item, index) => (
                <div key={index} className="relative group">
                  {item.href ? (
                    <Link 
                      href={item.href}
                      onClick={handleLinkClick}
                      className="px-4 py-2 text-gray-700 hover:text-blue-600 transition-all duration-200 text-sm font-medium"
                    >
                      {item.title}
                    </Link>
                  ) : (
                    <>
                      <button className="px-4 py-2 text-gray-700 hover:text-blue-600 transition-all duration-200 flex items-center gap-1 text-sm font-medium group-hover:text-blue-600">
                        {item.title}
                        <ChevronDown className="w-4 h-4 transform group-hover:rotate-180 transition-transform duration-200" />
                      </button>
                      {item.submenu && <DesktopSubMenu items={item.submenu} />}
                    </>
                  )}
                </div>
              ))}
            </div>

            {/* CTA Button */}
            <Link
              href="/contact"
              onClick={handleLinkClick}
              className="ml-8 px-6 py-2.5 text-sm font-semibold text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow-sm hover:shadow-md transition-all duration-200 flex items-center"
            >
              Get Started
              <ArrowRight className="w-4 h-4 ml-2" />
            </Link>
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            className="md:hidden rounded-md p-2 text-gray-700 hover:bg-gray-100"
          >
            {isMobileMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
          </button>
        </div>
      </nav>

      {/* Mobile Menu */}
      <div 
        className={`md:hidden bg-white border-t border-gray-200 absolute w-full left-0 transition-all duration-300 ${
          isMobileMenuOpen ? 'opacity-100 visible' : 'opacity-0 invisible'
        }`}
      >
        <div className="py-2">
          {menuItems.map((item, index) => (
            <div key={index} className="border-b border-gray-100 last:border-none">
              {item.href ? (
                <Link 
                  href={item.href}
                  onClick={handleLinkClick}
                  className="block px-4 py-3 text-gray-700 hover:bg-gray-50"
                >
                  {item.title}
                </Link>
              ) : (
                <div>
                  <button
                    onClick={() => toggleMobileMenu(item.title)}
                    className="flex items-center justify-between w-full px-4 py-3 text-gray-700 hover:bg-gray-50"
                  >
                    <span>{item.title}</span>
                    <ChevronDown 
                      className={`w-4 h-4 transition-transform duration-200 ${
                        activeMobileMenus.includes(item.title) ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  {activeMobileMenus.includes(item.title) && item.submenu && (
                    <div className="bg-gray-50 py-2">
                      {item.submenu.map((subItem, subIndex) => (
                        <Link
                          key={subIndex}
                          href={subItem.href}
                          onClick={handleLinkClick}
                          className="block px-8 py-2 text-gray-600 hover:bg-gray-100"
                        >
                          {subItem.title}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          ))}
          
          {/* Mobile CTA Button */}
          <div className="p-4">
            <Link
              href="/contact"
              onClick={handleLinkClick}
              className="block w-full text-center px-6 py-2.5 text-sm font-semibold text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow-sm hover:shadow-md transition-all duration-200"
            >
              Get Started
              <ArrowRight className="w-4 h-4 ml-2 inline-block" />
            </Link>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderMenu;