"use client"
import React, { useState } from 'react';
import { 
  Linkedin, 
  Twitter, 
  Facebook, 
  Instagram,
  CircuitBoard,
  Mail,
  MapPin,
  X
} from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose,
} from "@/components/ui/dialog";
import { Switch } from "@/components/ui/switch";
import CTASection from './CTASection';
import Link from 'next/link';

const Footer = () => {
  const [isCookieDialogOpen, setIsCookieDialogOpen] = useState(false);
  const [isAccessibilityDialogOpen, setIsAccessibilityDialogOpen] = useState(false);
  const [cookiePreferences, setCookiePreferences] = useState({
    necessary: true,
    analytics: false,
    marketing: false,
    preferences: false
  });

  const currentYear = new Date().getFullYear();
    
  const quickLinks = [
    { name: 'Home', href: '/' },
    { name: 'Services', href: '/services' },
    { name: 'Careers', href: '/career' },
    { name: 'Contact Us', href: '/contact' }
  ];

  const legalLinks = [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Terms of Use', href: '/terms-of-use' }
  ];

  const socialLinks = [
    { Icon: Linkedin, label: 'LinkedIn', href: 'https://linkedin.com/company/ivlsi' },
    { Icon: Twitter, label: 'Twitter', href: 'https://twitter.com/ivlsi' },
    { Icon: Facebook, label: 'Facebook', href: 'https://facebook.com/ivlsi' },
    { Icon: Instagram, label: 'Instagram', href: 'https://instagram.com/ivlsi' }
  ];

  const handleCookiePreferenceChange = (key: string) => {
    if (key === 'necessary') return; // Necessary cookies can't be disabled
    setCookiePreferences(prev => ({
      ...prev,
      [key]: !prev[key as keyof typeof cookiePreferences]
    }));
  };

  const saveCookiePreferences = () => {
    // Here you would typically save these preferences to localStorage or send to your backend
    setIsCookieDialogOpen(false);
  };
  
  return (
    <>
      <CTASection/>
      <footer className="bg-gradient-to-b from-gray-50 to-gray-100">
        {/* Main footer content remains the same until the copyright section */}
        <div className="container mx-auto px-4 py-12">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            {/* Company Info */}
            <div className="space-y-4">
              <div className="flex items-center space-x-2">
                <CircuitBoard className="w-8 h-8 text-blue-600" />
                <span className="text-xl font-bold bg-gradient-to-r from-blue-600 to-indigo-600 bg-clip-text text-transparent">
                  iVLSI Technologies
                </span>
              </div>
              <p className="text-gray-600 text-sm">
                Leading provider of VLSI design services and semiconductor solutions.
              </p>
              <div className="space-y-2">
                <div className="flex items-center space-x-2 text-gray-600">
                  <Mail className="w-4 h-4" />
                  <span className="text-sm">info@ivlsi.com</span>
                </div>
                <div className="flex items-center space-x-2 text-gray-600">
                  <MapPin className="w-4 h-4" />
                  <span className="text-sm">DCB 223/224, DLF Cybercity, Bhubaneswar</span>
                </div>
              </div>
            </div>

            {/* Quick Links */}
            <div>
              <h3 className="text-gray-800 font-semibold mb-4">Quick Links</h3>
              <ul className="space-y-2">
                {quickLinks.map((link) => (
                  <li key={link.name}>
                    <Link 
                      href={link.href}
                      className="text-gray-600 hover:text-blue-600 transition-colors duration-200 text-sm"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Legal */}
            <div>
              <h3 className="text-gray-800 font-semibold mb-4">Legal</h3>
              <ul className="space-y-2">
                {legalLinks.map((link) => (
                  <li key={link.name}>
                    <Link 
                      href={link.href}
                      className="text-gray-600 hover:text-blue-600 transition-colors duration-200 text-sm"
                    >
                      {link.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            {/* Social Media */}
            <div>
              <h3 className="text-gray-800 font-semibold mb-4">Connect With Us</h3>
              <div className="flex space-x-4">
                {socialLinks.map(({ Icon, label, href }) => (
                  <a
                    key={label}
                    href={href}
                    className="w-8 h-8 flex items-center justify-center rounded-full bg-white shadow-sm text-gray-600 hover:text-blue-600 hover:shadow-md transform hover:-translate-y-1 transition-all duration-200"
                    aria-label={label}
                  >
                    <Icon className="w-4 h-4" />
                  </a>
                ))}
              </div>
            </div>
          </div>

          {/* Divider */}
          <div className="border-t border-gray-200 my-8" />

          {/* Copyright */}
          <div className="flex flex-col md:flex-row justify-between items-center text-sm text-gray-600">
            <p>© {currentYear} iVLSI Technologies. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 md:mt-0">
              <button 
                onClick={() => setIsCookieDialogOpen(true)}
                className="hover:text-blue-600 transition-colors duration-200"
              >
                Cookie Settings
              </button>
              <button 
                onClick={() => setIsAccessibilityDialogOpen(true)}
                className="hover:text-blue-600 transition-colors duration-200"
              >
                Accessibility
              </button>
            </div>
          </div>
        </div>
      </footer>

      {/* Cookie Settings Dialog */}
      <Dialog open={isCookieDialogOpen} onOpenChange={setIsCookieDialogOpen}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Cookie Settings</DialogTitle>
            <DialogDescription>
              Manage your cookie preferences. Some cookies are necessary for the website to function and cannot be disabled.
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4 py-4">
            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Necessary Cookies</p>
                <p className="text-sm text-gray-500">Required for the website to function properly</p>
              </div>
              <Switch checked disabled />
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Analytics Cookies</p>
                <p className="text-sm text-gray-500">Help us improve our website by collecting usage information</p>
              </div>
              <Switch 
                checked={cookiePreferences.analytics}
                onCheckedChange={() => handleCookiePreferenceChange('analytics')}
              />
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Marketing Cookies</p>
                <p className="text-sm text-gray-500">Used to deliver relevant advertisements</p>
              </div>
              <Switch 
                checked={cookiePreferences.marketing}
                onCheckedChange={() => handleCookiePreferenceChange('marketing')}
              />
            </div>

            <div className="flex items-center justify-between">
              <div>
                <p className="font-medium text-gray-900">Preference Cookies</p>
                <p className="text-sm text-gray-500">Remember your settings and preferences</p>
              </div>
              <Switch 
                checked={cookiePreferences.preferences}
                onCheckedChange={() => handleCookiePreferenceChange('preferences')}
              />
            </div>
          </div>

          <DialogFooter>
            <DialogClose asChild>
              <button className="px-4 py-2 text-gray-500 hover:text-gray-700">
                Cancel
              </button>
            </DialogClose>
            <button
              onClick={saveCookiePreferences}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
            >
              Save Preferences
            </button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Accessibility Dialog */}
      <Dialog open={isAccessibilityDialogOpen} onOpenChange={setIsAccessibilityDialogOpen}>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>Accessibility</DialogTitle>
            <DialogDescription>
              We are committed to making our website accessible to everyone.
            </DialogDescription>
          </DialogHeader>
          
          <div className="space-y-4 py-4">
            <div>
              <h4 className="font-medium text-gray-900 mb-2">Navigation</h4>
              <p className="text-sm text-gray-600">
                Our website supports keyboard navigation. Use Tab to move between elements and Enter to activate links and buttons.
              </p>
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Screen Readers</h4>
              <p className="text-sm text-gray-600">
                We follow WCAG 2.1 guidelines and provide appropriate ARIA labels and roles for screen reader compatibility.
              </p>
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Text Size</h4>
              <p className="text-sm text-gray-600">
                You can adjust the text size using your browser's zoom settings (usually Ctrl/Cmd + or -).
              </p>
            </div>

            <div>
              <h4 className="font-medium text-gray-900 mb-2">Contact Us</h4>
              <p className="text-sm text-gray-600">
                If you encounter any accessibility issues, please contact us at accessibility@ivlsi.com
              </p>
            </div>
          </div>

          <DialogFooter>
            <DialogClose asChild>
              <button className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700">
                Close
              </button>
            </DialogClose>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Footer;